import { template as template_51aa83b2dbcf49528286e7039b5b9890 } from "@ember/template-compiler";
const FKText = template_51aa83b2dbcf49528286e7039b5b9890(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
