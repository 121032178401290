import { template as template_95f1a50f430e4cd49fa75321fac82f69 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuShareButton = template_95f1a50f430e4cd49fa75321fac82f69(`
  <DButton
    class="post-action-menu__share share"
    ...attributes
    @action={{@buttonActions.share}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.share_action"}}
    @title="post.controls.share"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuShareButton;
