import { template as template_6f0579d30c0e423198a297092e36a3bc } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_6f0579d30c0e423198a297092e36a3bc(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
