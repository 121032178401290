import { template as template_4a98a9f195734826ab9af58e0c6a2b02 } from "@ember/template-compiler";
const FKLabel = template_4a98a9f195734826ab9af58e0c6a2b02(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
