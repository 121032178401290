import { template as template_022aaced00744bde9e789c87ab2baf54 } from "@ember/template-compiler";
const WelcomeHeader = template_022aaced00744bde9e789c87ab2baf54(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
